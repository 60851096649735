import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import CartIsEmpty from "../../images/svg/cartIsEmpty.svg"

const EmptyCart = () => {
  return (
    <Cart>
      <CartIsEmpty />
      <h1>Korpa je prazna</h1>
      <Link to="/#kategorije">KATEGORIJE</Link>
    </Cart>
  )
}

export default EmptyCart

const Cart = styled.div`
  text-align: center;
  svg {
    max-width: 600px;
  }
  h1 {
    font-size: 36px;
  }
  a {
    background-color: #fff;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.1);
    color: #4881d7;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.2rem;
    padding: 10px 20px;
    transition: all 0.3s;
  }
`
