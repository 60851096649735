import React, { useContext, useState, useEffect } from "react"
import styled from "styled-components"

import { CartContext } from "../context/cartContext"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Paypal from "../components/Paypal"
import EmptyCart from "../components/cart/emptyCart"

import MasterCard from "../images/svg/cards/mastercard.svg"
import Visa from "../images/svg/cards/visa.svg"
import Amex from "../images/svg/cards/amex.svg"
import Discover from "../images/svg/cards/discover.svg"

const Cart = () => {
  const [total, setTotal] = useState([])
  const [cart, setCart] = useContext(CartContext)
  const [loaded, setLoaded] = useState(false)

  const handleRemove = id => {
    const newCart = cart.filter(item => item.id !== id)

    setCart(newCart)
  }

  useEffect(() => {
    let totalPrice = []
    if (cart.length > 0) {
      totalPrice = cart.reduce((acc, item) => {
        return acc + item.price
      }, 0)
    }
    setTotal(totalPrice)

    setTimeout(() => {
      setLoaded(true)
    }, 800)
  }, [cart, loaded])

  return (
    <Layout>
      <SEO title="Korpa" />
      {cart.length < 1 && <EmptyCart />}
      {cart.length > 0 && (
        <Section>
          <div className="list">
            <div className="item">
              <h2>Prikaz</h2>
              <h2>Naziv</h2>
              <h2>Cijena</h2>
            </div>
            {cart.map(item => (
              <div className="item" key={item.id}>
                <div className="img">
                  <img src={item.image} alt={item.name} />
                </div>
                <h3>{item.name}</h3>
                <p>{item.price}€</p>
                <button
                  className="remove"
                  onClick={() => handleRemove(item.id)}
                >
                  X
                </button>
              </div>
            ))}
            <div className="total">
              <p>{total}€</p>
            </div>
          </div>
          {loaded && (
            <div className="paypal">
              <Paypal total={total} items={cart} />
            </div>
          )}
          <div className="acceptedCards">
            <MasterCard />
            <Visa />
            <Amex />
            <Discover />
          </div>
        </Section>
      )}
    </Layout>
  )
}

export default Cart

const Section = styled.section`
  .list {
    margin: 40px auto;
    max-width: 960px;
    .item {
      border-bottom: 1px solid #ddd;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      text-align: center;
      > * {
        width: 30%;
      }
      img {
        border-radius: 5px;
        height: 50px;
        width: 50px;
      }
      .remove {
        background-color: #c74e4e;
        border-radius: 50%;
        border: none;
        color: #f1f1f0;
        cursor: pointer;
        font-weight: bold;
        width: 25px;
        height: 25px;
        position: absolute;
        right: 0px;
      }
    }
    .total {
      font-weight: bold;
      text-align: center;
      font-size: 32px;
    }
  }
  .paypal {
    > div {
      display: flex;
      justify-content: center;
    }
  }
  .acceptedCards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    svg {
      background-color: #fff;
      box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      height: 40px;
      margin: 20px;
      padding: 10px 20px;
      width: 60px;
    }
  }
`
