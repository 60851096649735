import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"

export default function Paypal(props) {
  const [paidFor, setPaidFor] = useState(false)
  const [error, setError] = useState(null)
  const paypalRef = useRef()

  useEffect(() => {
    const items = props.items.map(item => item["name"])
    if (window.checkout) window.checkout.close()

    if (props.total > 1) {
      window.checkout = window.paypal.Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                description: items.toString(),
                amount: {
                  currency_code: "EUR",
                  locale: "en_ME",
                  value: props.total,
                },
              },
            ],
          })
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture()
          setPaidFor(true)
          console.log(order)
        },
        onError: err => {
          setError(err)
        },
      })
      window.checkout.render(paypalRef.current)
    }
  }, [props.total, props.items])

  if (paidFor) {
    return (
      <div>
        <h1 style={{ display: "block" }}>
          Čestitamo, vaša narudžba je uspjesno obavljena!
        </h1>
      </div>
    )
  }

  return <div ref={paypalRef} />
}
